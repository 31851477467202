import { config } from "@/utils/config";
import request from "./requestToken";

const defaultOptions = {
    dev3: true
};

export const getUserProfile = (data, cancelToken) =>
    request({
        ...defaultOptions,
        // url: `${config.api_ver}/profile/${userId}`,
        // here 'baseURL' is for testing only
        // baseURL: 'https://staging.cuddlynest.com/api',
        url: `${config.api_ver}/profile`,
        method: 'GET',
        cancelToken
    });

export const updateUserProfilePicture = (data, cancelToken) =>
    request({
        ...defaultOptions,
        url: `${config.api_ver}/profile/upload_photo`,
        method: 'POST',
        data,
        cancelToken
    });

export const updateUserProfile = (data, cancelToken) =>
    request({
        ...defaultOptions,
        url: `${config.api_ver}/profile/update`,
        method: 'POST',
        data,
        cancelToken
    });

export const addUserPhoneNumber = (data, cancelToken) =>
    request({
        ...defaultOptions,
        url: `${config.api_ver}/phone_number/add`,
        method: 'POST',
        data,
        cancelToken
    });

export const updateUserPhoneNumber = (data, cancelToken) =>
    request({
        ...defaultOptions,
        url: `${config.api_ver}/phone_number/update`,
        method: 'POST',
        data,
        cancelToken
    });

export const getUserUpcomingBookings = (data, cancelToken) =>{
    return request({
        ...defaultOptions,
        url: `${config.api_ver}/trips/upcoming`,
        method: 'GET',
        cancelToken
    });}

export const getUserPastBookings = (data, cancelToken) =>{
    return request({
        ...defaultOptions,
        url: `${config.api_ver}/trips/past`,
        method: 'GET',
        cancelToken
    });}

export const getUserWishlists = (data, cancelToken) =>{
    return request({
        ...defaultOptions,
        url: `${config.api_ver}/wishlists?listings=1`,
        method: 'GET',
        cancelToken
    });}

export const updateWishlistTitle = (data, cancelToken) =>{
    return request({
        ...defaultOptions,
        url: `${config.api_ver}/wishlist/${data.id}`,
        data: {name: data.name},
        method: 'POST',
        cancelToken
    });}

export const deleteWishlist = (data, cancelToken) =>{
    return request({
        ...defaultOptions,
        url: `${config.api_ver}/wishlist/${data}`,
        method: 'DELETE',
        cancelToken
    });}

export const deleteWishlistListing = (data, cancelToken) =>{
    return request({
        ...defaultOptions,
        url: `${config.api_ver}/wishlist/${data.wishlistId}/listing/${data.listingId}`,
        method: 'DELETE',
        cancelToken
    });}

export const undoDeleteWishlist = (data, cancelToken) =>{
    return request({
        ...defaultOptions,
        url: `${config.api_ver}/wishlist/${data}/undo`,
        method: 'DELETE',
        cancelToken
    });}
