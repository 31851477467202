import useAuth from "@/components/auth/useAuth";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const useEntityAction = actionHandler => {
    const dispatch = useDispatch();
    const cancellationSourceRef = useRef();
    const [entityActionPayload, setEntityActionPayload] = useState(null);

    useAuth({
        onSignIn: async user => {
            const [requestPayload, cancellationSource] = await dispatch(actionHandler(user));
            cancellationSourceRef.current = cancellationSource;
            const entityPayload = await requestPayload;

            setEntityActionPayload(entityPayload);
        }
    });

    useEffect(() => () => {
        cancellationSourceRef.current?.cancel();
    }, []);

    return entityActionPayload;
};

export default useEntityAction;
