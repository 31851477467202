import { PROFILE, useDashboardEntityRequestProcessingStatusSelector, useDashboardSelector } from "@/redux/reducers/dashboardReducer";
import { EntityRequestProcessingStatus } from "@/utils/entityRequestAdapter";
import { useEffect, useState } from "react";

const useEntity = (entity = PROFILE) => {
    const entityRequestProcessingStatus = useDashboardEntityRequestProcessingStatusSelector(entity);
    const [isLoaded, setLoadedState] = useState(false);

    useEffect(() => {
        const completedActions = [
            EntityRequestProcessingStatus.RESOLVED_SUCCESS,
            EntityRequestProcessingStatus.RESOLVED_ERROR,
            EntityRequestProcessingStatus.CANCELLED
        ];

        if (!isLoaded && completedActions.includes(entityRequestProcessingStatus)) {
            setLoadedState(true);
        }
    }, [entityRequestProcessingStatus]);

    return {
        [entity]: useDashboardSelector(entity),
        isLoaded
    };
};

export default useEntity;
