

import clsx from "clsx";
import { memo, useCallback, useEffect, useState} from "react";
import { setSwitcherActions } from "../../redux/actions/dashboardRoutesActions";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";


const SwitcherComponent = ({ ...props}) => {
    const dispatch = useDispatch();
    const router = useRouter();

    const [active, setActive] = useState("guest");
    const {dashboardSwitcher} = useSelector(state=>state.dashboardRoutesReducer)

    const redirect = useCallback(()=>{
        !props.noRedirect && router.push(`/d`)
    },[props.noRedirect])

    useEffect(()=>{
        if(props.noRedirect) {
            return;
        }
        const defaultSwitcher = window.location.pathname.split('/');
        if(defaultSwitcher[2] === 'host') {
            dispatch(setSwitcherActions('host'));
        } 
        if(defaultSwitcher[2] === 'guest') {
            dispatch(setSwitcherActions('guest'));
        }
    },[window.location.pathname, props.noRedirect])

    useEffect(()=>{
        setActive(dashboardSwitcher)
    },[dashboardSwitcher])

    const [isActive, setIsActive] = useState(null);
    const [pointerPos, setPointerPos] = useState(null);

    const onClickHandler = useCallback((type)=>()=>{
        dispatch(setSwitcherActions(type));
        redirect && redirect()
    },[active])
    
    const getActiveState = useCallback(()=>{
        setIsActive(active)
    },[active])

    useEffect(()=>{
        getActiveState();
    },[active])

    return (
        <div className={clsx("switcherComponent", props.className)}>
            {isActive && <div className="switcherComponent-innerWrapper">
                <div onMouseOver={()=>setPointerPos('guest')} onMouseOut={()=>setPointerPos(null)} onClick={onClickHandler('guest')} className={`switcherComponent-innerWrapper-navigator ${!pointerPos ? (isActive === 'guest' ? 'active' : '') : (pointerPos === 'guest' ? 'active' : '')}`}>
                    Traveler
                </div>
                <div onMouseOver={()=>setPointerPos('host')} onMouseOut={()=>setPointerPos(null)} onClick={onClickHandler('host')} className={`switcherComponent-innerWrapper-navigator ${!pointerPos ? (isActive === 'host' ? 'active' : '') : (pointerPos === 'host' ? 'active' : '')}`}>   
                    Nester
                </div>
                <div className="switcherComponent-blob" style={{transform:`translateX(${!pointerPos ? (isActive === 'host' ? 'calc(100% - 8px)' : '8px') : pointerPos === 'host' ? 'calc(100% - 8px)' : '8px'})`}} />
            </div>}
        </div>
    );
};

export default memo(SwitcherComponent);
