import {useCallback} from "react"
import { useDispatch, useSelector } from "react-redux";
import {useEffect} from "react"
import { getUserProfile } from "../redux/actions/dashboardProfileActions";

const useAuthHook = ({isCalled}) => {
    const dispatch = useDispatch();
    const loggedUser = useSelector(state=>state.authReducer); 
    
    useEffect(() => {
      
      if(isCalled === true) {
        return ;
      } 

      if(!loggedUser?.data?._id) {
        return;
      }

      const fetchUserProfile = async () => {
        console.log("FETCHING")
        return await getUserProfile(dispatch, loggedUser.data._id)
      }
      fetchUserProfile();

    }, [dispatch, loggedUser])



    const reFetchProfile = useCallback(
      () => {
        if(!loggedUser?.data?._id) {
          return;
        }
  
        const fetchUserProfile = async () => {
          return await getUserProfile(dispatch, loggedUser.data._id)
        }
        fetchUserProfile();
      },
      [dispatch, loggedUser],
    )

    return {
      reFetchProfile
    }
    
}


export default useAuthHook;