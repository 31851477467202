import { processEntityRequest } from "@/utils/entityRequestAdapter";
import {
	getUserProfile as getUserProfileRequest,
	updateUserProfilePicture as updateUserProfilePictureRequest,
	updateUserProfile as updateUserProfileRequest,
	updateUserPhoneNumber as updateUserPhoneNumberRequest,
	addUserPhoneNumber as addUserPhoneNumberRequest,
	getUserUpcomingBookings as getUserUpcomingBookingsRequest,
	getUserPastBookings as getUserPastBookingsRequest,
	getUserWishlists as getUserWishlistsRequest,
	updateWishlistTitle as updateWishlistTitleRequest,
	deleteWishlist as deleteUserWishlistsRequest,
	undoDeleteWishlist as undoDeleteUserWishlistsRequest,
	deleteWishlistListing as deleteUserWishlistListingRequest,
} from "@/redux/services/dashboard";
import {
	DASHBOARD_ADD_PROFILE_PHONE,
	DASHBOARD_UPDATE_PROFILE,
	DASHBOARD_UPDATE_PROFILE_PHONE,
	DASHBOARD_UPDATE_PROFILE_PICTURE,
	DASHBOARD_USER_PROFILE,
	DASHBOARD_GET_USER_UPCOMING_BOOKINGS,
	DASHBOARD_GET_USER_PAST_BOOKINGS,
	DASHBOARD_GET_USER_WISHLISTS,
	DASHBOARD_UPDATE_USER_WISHLISTS,
	DASHBOARD_DELETE_USER_WISHLISTS,
	DASHBOARD_UNDO_DELETE_USER_WISHLISTS,
	DASHBOARD_DELETE_USER_WISHLIST_LISTING,
	DASHBOARD_GET_USER_WATCHLIST,
	DASHBOARD_ADD_PROPERTY_TO_WATCHLIST,
	DASHBOARD_REMOVE_PROPERTY_FROM_WATCHLIST,
	DASHBOARD_UPDATE_WATCHLIST_PROPERTIES,
	DASHBOARD_GET_NOTIFICATION_BY_OBJECT,
	DASHBOARD_GET_NOTIFICATION_LISTING,
	DASHBOARD_UPDATE_NOTIFICATION_SETTINGS,
	DASHBOARD_GET_NOTIFICATION_SETTINGS,
	DASHBOARD_GET_USER_CANCELLED_BOOKINGS,
	DASHBOARD_GET_USER_CURRENT_BOOKINGS,
	DASHBOARD_GET_BOOKING_DETAILS,
	DASHBOARD_CANCEL_BOOKING,
	DASHBOARD_GET_REFUND_INFO,
	DASHBOARD_PAYMENT_DELETE_CARD,
	DASHBOARD_PAYMENT_SET_DEFAULT_CARD,
	DASHBOARD_UPDATE_PASSWORD,
} from "../types/dashboard";
import {
	addUserToSubscriberList,
	getObjectNotifications,
	getSubscribedProperties,
	getUserNotificationListing,
	markNotificationAsRead as markNotificationAsReadRequest,
	markAllNotificationsAsRead as markAllNotificationsAsReadRequest,
	removePropertyFromWatchlist,
	updateSubscriberProperty,
	getUserNotificationSettings,
	updateUserNotificationSettings,
	getCurrentBookings,
	getCancelledBookings,
	getBookingDetails,
	getRefundInformation,
	cancelBooking,
	deletePaymentMethod,
	setDefaultPaymentMethod,
	updatePassword,
} from "@/containers/Dashboard/redux/services/dashboardServices";

export const getUserProfile = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: getUserProfileRequest,
		requestParams,
		actionType: DASHBOARD_USER_PROFILE,
	});

export const updateUserProfilePicture = ({ requestParams, dataTransformer }) =>
	dashboardStateEntityRequestDecorator({
		request: updateUserProfilePictureRequest,
		requestParams,
		actionType: DASHBOARD_UPDATE_PROFILE_PICTURE,
		dataTransformer,
	});

export const updateUserProfile = ({ requestParams, dataTransformer }) =>
	dashboardStateEntityRequestDecorator({
		request: updateUserProfileRequest,
		requestParams,
		actionType: DASHBOARD_UPDATE_PROFILE,
		dataTransformer,
	});

export const updateUserPhoneNumber = ({ requestParams, dataTransformer }) =>
	dashboardStateEntityRequestDecorator({
		request: updateUserPhoneNumberRequest,
		requestParams,
		actionType: DASHBOARD_UPDATE_PROFILE_PHONE,
		dataTransformer,
	});

export const addUserPhoneNumber = ({ requestParams, dataTransformer }) =>
	dashboardStateEntityRequestDecorator({
		request: addUserPhoneNumberRequest,
		requestParams,
		actionType: DASHBOARD_ADD_PROFILE_PHONE,
		dataTransformer,
	});


export const updateUserPasswordAction = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: updatePassword,
		requestParams,
		actionType: DASHBOARD_UPDATE_PASSWORD,
		// dataTransformer,
	});

export const getUserCurrentBookings = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: getCurrentBookings,
		requestParams,
		actionType: DASHBOARD_GET_USER_CURRENT_BOOKINGS,
	});

export const getUserUpcomingBookings = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: getUserUpcomingBookingsRequest,
		requestParams,
		actionType: DASHBOARD_GET_USER_UPCOMING_BOOKINGS,
	});

export const getUserPastBookings = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: getUserPastBookingsRequest,
		requestParams,
		actionType: DASHBOARD_GET_USER_PAST_BOOKINGS,
	});

export const getUserCancelledBookings = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: getCancelledBookings,
		requestParams,
		actionType: DASHBOARD_GET_USER_CANCELLED_BOOKINGS,
	});

export const getUserBookingDetails = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: getBookingDetails,
		requestParams,
		actionType: DASHBOARD_GET_BOOKING_DETAILS,
	});

export const getUserWishlists = (requestParams) => getUserWishlistsRequest(requestParams);

export const updateUserWishlist = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: updateWishlistTitleRequest,
		requestParams,
		actionType: DASHBOARD_UPDATE_USER_WISHLISTS,
	});

export const deleteUserWishlist = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: deleteUserWishlistsRequest,
		requestParams,
		actionType: DASHBOARD_DELETE_USER_WISHLISTS,
	});

export const deleteUserWishlistListing = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: deleteUserWishlistListingRequest,
		requestParams,
		actionType: DASHBOARD_DELETE_USER_WISHLIST_LISTING,
	});

export const undoDeleteUserWishlist = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: undoDeleteUserWishlistsRequest,
		requestParams,
		actionType: DASHBOARD_UNDO_DELETE_USER_WISHLISTS,
	});

export const getUserWatchlist = (requestParams) => getSubscribedProperties(requestParams);

export const addToWatchlist = (requestParams) => addUserToSubscriberList(requestParams);

export const removeFromWatchlist = (requestParams) => removePropertyFromWatchlist(requestParams);

export const updateWatchlistProperties = (requestParams) => updateSubscriberProperty(requestParams);

export const getNotificationListing = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: getUserNotificationListing,
		requestParams,
		actionType: DASHBOARD_GET_NOTIFICATION_LISTING,
	});

export const getNotificationByObject = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: getObjectNotifications,
		requestParams,
		actionType: DASHBOARD_GET_NOTIFICATION_BY_OBJECT,
	});

export const markNotificationAsRead = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: markNotificationAsReadRequest,
		requestParams,
		actionType: DASHBOARD_GET_NOTIFICATION_BY_OBJECT,
	});

export const markAllNotificationAsRead = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: markAllNotificationsAsReadRequest,
		requestParams,
		actionType: DASHBOARD_GET_NOTIFICATION_BY_OBJECT,
	});

export const getNotificationSetting = (requestParams) => getUserNotificationSettings(requestParams);

export const updateNotificationSetting = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: updateUserNotificationSettings,
		requestParams,
		actionType: DASHBOARD_UPDATE_NOTIFICATION_SETTINGS,
	});

export const getRefundInfoAction = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: getRefundInformation,
		requestParams,
		actionType: DASHBOARD_GET_REFUND_INFO,
	});

export const cancelBookingAction = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: cancelBooking,
		requestParams,
		actionType: DASHBOARD_CANCEL_BOOKING,
	});

export const deleteCardAction = (requestParams) =>
	dashboardStateEntityRequestDecorator({
		request: deletePaymentMethod,
		requestParams,
		actionType: DASHBOARD_PAYMENT_DELETE_CARD,
	});

	export const setDefaultCardAction = (requestParams) =>
		dashboardStateEntityRequestDecorator({
			request: setDefaultPaymentMethod,
			requestParams,
			actionType: DASHBOARD_PAYMENT_SET_DEFAULT_CARD,
		});

function dashboardStateEntityRequestDecorator(options = {}) {
	return processEntityRequest({
		...options,
		getState: (state) => state.dashboard,
	});
}
