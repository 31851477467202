
export const AuthCloseSVG = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M7.7312 8L15.9314 16.4853" stroke="#242526" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.9316 8L7.73139 16.4853" stroke="#242526" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export const AuthBackSVG = () => {
    return (
        <svg width="7" height="16" viewBox="0 0 7 16" fill="none">
            <path d="M6 15L1 8L6 1" stroke="#3E4041" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export const AuthExclamationSVG = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <mask id="mask0_1722_2340" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.00016 1.3335C4.32016 1.3335 1.3335 4.32016 1.3335 8.00016C1.3335 11.6802 4.32016 14.6668 8.00016 14.6668C11.6802 14.6668 14.6668 11.6802 14.6668 8.00016C14.6668 4.32016 11.6802 1.3335 8.00016 1.3335ZM8.00016 8.66683C7.6335 8.66683 7.3335 8.36683 7.3335 8.00016V5.3335C7.3335 4.96683 7.6335 4.66683 8.00016 4.66683C8.36683 4.66683 8.66683 4.96683 8.66683 5.3335V8.00016C8.66683 8.36683 8.36683 8.66683 8.00016 8.66683ZM7.3335 10.0002V11.3335H8.66683V10.0002H7.3335Z" fill="black" />
            </mask>
            <g mask="url(#mask0_1722_2340)">
                <rect width="16" height="16" fill="#DA1414" />
            </g>
        </svg>
    );
};

export const PasswordEyeSVG = ({ show }) => {
    return (
        show
            ? (
                <svg width="16" height="14" viewBox="0 0 16 14" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M3.365 3.36421C2.16007 4.11074 1.06582 5.13913 0.125192 6.38828C-0.0417306 6.61085 -0.0417306 6.92178 0.125192 7.14434C2.17081 9.8642 4.94303 11.5367 8 11.5367C9.03416 11.5367 10.0357 11.3453 10.9876 10.9868L9.68006 9.67927C9.24411 9.93319 8.74736 10.0919 8.21929 10.126C6.19004 10.2537 4.51427 8.58119 4.64191 6.54866C4.67498 6.01956 4.83302 5.5221 5.08656 5.08576L3.365 3.36421ZM6.26133 6.26054C6.22481 6.38548 6.20158 6.51589 6.19331 6.65012C6.1213 7.7433 7.02465 8.64337 8.11783 8.57464C8.25192 8.56635 8.38231 8.5431 8.50731 8.50652L6.26133 6.26054ZM9.36784 7.95283L6.81515 5.40014C7.10656 5.14703 7.47875 4.98451 7.88545 4.95799C8.97862 4.88925 9.88197 5.78933 9.80996 6.8825C9.78348 7.29006 9.62074 7.6619 9.36784 7.95283ZM10.4651 9.05012C10.9764 8.49873 11.307 7.77812 11.3581 6.98724C11.4857 4.95471 9.80996 3.28222 7.78071 3.40986C6.98829 3.45939 6.26685 3.78922 5.71539 4.30038L4.27819 2.86319C5.44414 2.30345 6.69617 1.99921 8 1.99921C11.057 1.99921 13.8292 3.6717 15.8748 6.39156C16.0417 6.61412 16.0417 6.92505 15.8748 7.14762C14.7629 8.62421 13.4364 9.79233 11.9661 10.5511L10.4651 9.05012Z" fill="#797882" />
                    <line x1="0.707107" y1="0.999207" x2="12.5897" y2="12.8818" stroke="#797882" strokeLinecap="round" />
                </svg>
            )
            : (
                <svg width="16" height="10" viewBox="0 0 16 10" fill="none">
                    <path d="M8 0.231628C4.94303 0.231628 2.17081 1.90413 0.125192 4.62071C-0.0417306 4.84327 -0.0417306 5.1542 0.125192 5.37677C2.17081 8.09662 4.94303 9.76911 8 9.76911C11.057 9.76911 13.8292 8.09662 15.8748 5.38004C16.0417 5.15747 16.0417 4.84654 15.8748 4.62398C13.8292 1.90413 11.057 0.231628 8 0.231628ZM8.21929 8.35846C6.19004 8.4861 4.51427 6.81361 4.64191 4.78108C4.74665 3.10531 6.10494 1.74702 7.78071 1.64229C9.80996 1.51464 11.4857 3.18714 11.3581 5.21966C11.2501 6.89216 9.89179 8.25045 8.21929 8.35846ZM8.11783 6.80706C7.02465 6.87579 6.1213 5.97572 6.19331 4.88254C6.24895 3.9792 6.9821 3.24932 7.88545 3.19041C8.97862 3.12168 9.88197 4.02175 9.80996 5.11493C9.75105 6.02154 9.0179 6.75142 8.11783 6.80706Z" fill="#797882" />
                </svg>
            )
    );
};

export const FacebookSVG = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path d="M24.4426 12.8544C24.4426 6.22697 19.07 0.854397 12.4426 0.854397C5.81518 0.854397 0.442596 6.22697 0.442596 12.8544C0.442596 18.8439 4.83082 23.8084 10.5676 24.7086V16.3231H7.52072V12.8544H10.5676V10.2106C10.5676 7.20315 12.3591 5.5419 15.1002 5.5419C16.4131 5.5419 17.7863 5.77627 17.7863 5.77627V8.7294H16.2732C14.7825 8.7294 14.3176 9.6544 14.3176 10.6034V12.8544H17.6457L17.1137 16.3231H14.3176V24.7086C20.0544 23.8084 24.4426 18.8439 24.4426 12.8544Z" fill="#1877F2" />
            <path d="M17.1137 16.3231L17.6457 12.8544H14.3176V10.6034C14.3176 9.65438 14.7825 8.72937 16.2732 8.72937H17.7863V5.77625C17.7863 5.77625 16.4131 5.54187 15.1002 5.54187C12.3591 5.54187 10.5676 7.20312 10.5676 10.2106V12.8544H7.52072V16.3231H10.5676V24.7086C11.1785 24.8044 11.8047 24.8544 12.4426 24.8544C13.0805 24.8544 13.7066 24.8044 14.3176 24.7086V16.3231H17.1137Z" fill="white" />
        </svg>
    );
};

export const GoogleSVG = () => {
    return (
        <svg viewBox="0 0 24 24" width="24" height="24">
            <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z" />
                <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z" />
                <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z" />
                <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z" />
            </g>
        </svg>
    );
};

export const AppleSVG = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M17.8672 13.3399C17.8441 10.7599 19.9744 9.52127 20.0708 9.46088C18.872 7.70703 17.0063 7.46676 16.3408 7.43849C14.7539 7.27789 13.2429 8.3726 12.4386 8.3726C11.6343 8.3726 10.3905 7.46163 9.0748 7.48732C7.34279 7.51302 5.74826 8.49338 4.85655 10.0429C3.05901 13.1613 4.39785 17.783 6.14785 20.3142C7.00486 21.5516 8.02505 22.9456 9.36646 22.8943C10.659 22.8429 11.1473 22.0591 12.7071 22.0591C14.267 22.0591 14.7064 22.8943 16.0722 22.8698C17.4612 22.8429 18.3426 21.6055 19.1932 20.3643C20.1748 18.9278 20.5808 17.5376 20.604 17.4644C20.5731 17.4515 17.8955 16.4249 17.8672 13.3399Z" fill="black" />
            <path d="M15.3012 5.76402C16.0131 4.90187 16.4936 3.70307 16.3612 2.50818C15.3358 2.54934 14.0947 3.19175 13.3585 4.0539C12.6993 4.81586 12.1211 6.03646 12.2778 7.20702C13.4214 7.29568 14.5893 6.62491 15.3012 5.76402Z" fill="black" />
        </svg>
    );
};

export const PhoneSVG = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M15.4783 14.2391C14.4348 15.2826 14.4348 16.3261 13.3913 16.3261C12.3478 16.3261 11.3043 15.2826 10.2609 14.2391C9.21739 13.1957 8.17391 12.1522 8.17391 11.1087C8.17391 10.0652 9.21739 10.0652 10.2609 9.02175C11.3043 7.97827 8.17391 4.84784 7.13043 4.84784C6.08696 4.84784 4 7.97827 4 7.97827C4 10.0652 6.14435 14.2965 8.17391 16.3261C10.2035 18.3557 14.4348 20.5 16.5217 20.5C16.5217 20.5 19.6522 18.4131 19.6522 17.3696C19.6522 16.3261 16.5217 13.1957 15.4783 14.2391Z" fill="#64748B" />
        </svg>
    );
};

export const EmailSVG = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M5.29241 8.10192C7.21386 9.72918 10.5854 12.592 11.5763 13.4846C11.7094 13.6051 11.852 13.6664 12.0002 13.6664C12.148 13.6664 12.2904 13.6057 12.4231 13.4858C13.4149 12.5923 16.7865 9.72918 18.7079 8.10192C18.8276 8.0008 18.8458 7.82307 18.749 7.69945C18.5251 7.41379 18.1913 7.25 17.8335 7.25H6.16684C5.8091 7.25 5.47526 7.41379 5.2514 7.69948C5.15455 7.82307 5.17278 8.0008 5.29241 8.10192Z" fill="#64748B" />
            <path d="M18.8308 8.98331C18.7274 8.93518 18.6058 8.95197 18.5198 9.02547C16.3889 10.8319 13.6694 13.1473 12.8138 13.9183C12.3335 14.3518 11.667 14.3518 11.1856 13.9177C10.2736 13.096 7.21966 10.4997 5.48021 9.02544C5.39361 8.95194 5.27171 8.93573 5.16918 8.98328C5.06609 9.03116 5 9.13427 5 9.24791V16.5829C5 17.2263 5.52322 17.7495 6.16668 17.7495H17.8334C18.4768 17.7495 19 17.2263 19 16.5829V9.24791C19 9.13427 18.9339 9.03089 18.8308 8.98331Z" fill="#64748B" />
        </svg>
    );
};

export const TickMarkSVG = () => {
    return (
        <svg width="61" height="60" viewBox="0 0 61 60" fill="none">
            <g clipPath="url(#clip0_507_11517)">
                <path d="M34.8987 1.49252L37.9704 3.84749C38.0776 3.93017 38.212 3.96978 38.3469 3.95855L42.203 3.6375C43.7881 3.50502 45.3726 3.8992 46.7108 4.75893C48.049 5.61865 49.0662 6.89587 49.6046 8.39256L50.9149 12.0369C50.9608 12.1642 51.0523 12.27 51.1717 12.3337L54.5888 14.1489C55.9935 14.8943 57.1131 16.0823 57.7739 17.5288C58.4347 18.9753 58.5998 20.5994 58.2436 22.1492L57.3758 25.9203C57.3451 26.0523 57.3649 26.191 57.4314 26.3091L59.3265 29.6827C60.1059 31.0698 60.4057 32.6753 60.1795 34.2502C59.9532 35.825 59.2136 37.2812 58.0752 38.3928L55.3072 41.0966C55.2105 41.1915 55.1524 41.3189 55.1441 41.4541L54.9133 45.3171C54.8183 46.9033 54.2033 48.414 53.1634 49.6155C52.1234 50.817 50.7165 51.6423 49.1604 51.9638L45.3702 52.743C45.2369 52.7692 45.1185 52.8448 45.0387 52.9547L42.7566 56.0785C41.8179 57.3647 40.4808 58.3049 38.9529 58.7534C37.425 59.2018 35.7918 59.1333 34.3069 58.5584L30.6972 57.1701C30.571 57.1216 30.4312 57.1216 30.305 57.1701L26.6953 58.5584C25.2117 59.1316 23.5804 59.1994 22.0543 58.7514C20.5282 58.3033 19.1925 57.3645 18.2542 56.0802L15.9704 52.9565C15.8909 52.8471 15.7732 52.7715 15.6407 52.7447L11.8488 51.9638C10.2907 51.6439 8.88167 50.8191 7.84001 49.6171C6.79834 48.4151 6.18223 46.9031 6.08718 45.3154L5.85637 41.4523C5.84802 41.3171 5.78991 41.1898 5.69324 41.0948L2.91656 38.3911C1.77886 37.2795 1.03948 35.8238 0.812962 34.2494C0.586439 32.6751 0.885419 31.0699 1.66359 29.6827L3.55867 26.3091C3.62517 26.191 3.64498 26.0523 3.6142 25.9203L2.74649 22.1492C2.38998 20.5993 2.55473 18.9751 3.2152 17.5284C3.87566 16.0816 4.99496 14.8931 6.39956 14.1472L9.81833 12.332C9.93773 12.2682 10.0292 12.1625 10.0752 12.0352L11.3854 8.39082C11.9243 6.89385 12.9421 5.61656 14.281 4.75711C15.6199 3.89766 17.2051 3.50411 18.7905 3.6375L22.6483 3.95855C22.7833 3.96978 22.9176 3.93017 23.0249 3.84749L26.0949 1.49252C27.3574 0.52277 28.9048 -0.00292969 30.4968 -0.00292969C32.0887 -0.00292969 33.6361 0.52277 34.8987 1.49252Z" fill="#6FCF97" />
                <path d="M42.8968 22.7354C42.5215 22.362 42.0136 22.1523 41.4842 22.1523C40.9548 22.1523 40.4468 22.362 40.0716 22.7354L27.3145 35.4925L20.9299 29.1062C20.5546 28.7327 20.0467 28.5231 19.5172 28.5231C18.9878 28.5231 18.4799 28.7327 18.1046 29.1062C17.7311 29.4815 17.5215 29.9894 17.5215 30.5188C17.5215 31.0483 17.7311 31.5562 18.1046 31.9315L25.9053 39.7304C26.2806 40.1039 26.7885 40.3135 27.3179 40.3135C27.8474 40.3135 28.3553 40.1039 28.7306 39.7304L42.9003 25.5607C43.2733 25.185 43.4823 24.6768 43.4817 24.1474C43.481 23.6179 43.2707 23.1103 42.8968 22.7354Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_507_11517">
                    <rect width="59.5024" height="59.0443" fill="white" transform="translate(0.749023)" />
                </clipPath>
            </defs>
        </svg>
    );
};

