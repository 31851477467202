import { config } from "@/utils/config";
import request from "../../../../redux/services/requestToken";
import { serialize } from "@/src/utils/globalFunc";
import requestToken from "../../../../redux/services/requestToken";

const defaultOptions = {
	dev3: true,
};

export const getUserUpcomingBookings = (params = {
	limit: 10,
	page: 0
}, cancelToken) => {
	const filters = serialize(params || {})
	return request({
		...defaultOptions,
		url: `${config.api_ver}/trips/upcoming?${filters}`,
		method: "GET",
		cancelToken,
	});
};

export const getCurrentBookings = (params = {
	limit: 10,
	page: 0
}, cancelToken) => {
	const filters = serialize(params || {})
	return request({
		...defaultOptions,
		url: `${config.api_ver}/trips/current?${filters}`,
		method: "GET",
		cancelToken,
	});
};

export const getUserPastBookings = (params = {
	limit: 10,
	page: 0
}, cancelToken) => {
	const filters = serialize(params || {})
	return request({
		...defaultOptions,
		url: `${config.api_ver}/trips/past?${filters}`,
		method: "GET",
		cancelToken,
	});
};

export const getCancelledBookings = (params = {
	limit: 10,
	page: 0
}, cancelToken) => {
	const filters = serialize(params || {})
	return request({
		...defaultOptions,
		url: `${config.api_ver}/trips/cancelled?${filters}`,
		method: "GET",
		cancelToken,
	});
};

export const getBookingDetails = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/trips/detail/${data.booking_id}`,
		method: "GET",
		cancelToken,
	});
};

export const getBookingRequestDetails = (bookingId,token) => {
	return requestToken({
		...defaultOptions,
		url: `${config.api_ver}/trips/detail/${bookingId}?user_type=host`,
		method: "GET",
		headers:  { 'X-Authorization': `Bearer ${token}` }
	});
};

export const updatePassword = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/password/update`,
		method: "POST",
		data,
		cancelToken,
	});
};

export const getUserWishlists = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/wishlists?listings=1`,
		method: "GET",
		cancelToken,
	});
};

export const updateWishlistTitle = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/wishlist/${data.id}`,
		data: { name: data.name },
		method: "POST",
		cancelToken,
	});
};

export const deleteWishlist = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/wishlist/${data}`,
		method: "DELETE",
		cancelToken,
	});
};

export const deleteWishlistListing = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/wishlist/${data.wishlistId}/listing/${data.listingId}`,
		method: "DELETE",
		cancelToken,
	});
};

export const undoDeleteWishlist = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/wishlist/${data}/undo`,
		method: "DELETE",
		cancelToken,
	});
};

export const getSubscribedProperties = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `https://watchapi.cuddlynest.com/get_subscriber_list_by_id/${data.user_id}`,
		method: "GET",
		cancelToken,
	});
};

export const addUserToSubscriberList = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `https://watchapi.cuddlynest.com/add_subscriber_property`,
		method: "POST",
		data,
		cancelToken,
	});
};

export const updateSubscriberProperty = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `https://watchapi.cuddlynest.com/update_subscriber_property`,
		method: "POST",
		data,
		cancelToken,
	});
};

export const removePropertyFromWatchlist = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `https://watchapi.cuddlynest.com/unsubscribe_property`,
		method: "POST",
		data,
		cancelToken,
	});
};

export const checkAndNotifyAllProperties = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `https://watchapi.cuddlynest.com/check_and_notify_all_properties`,
		method: "POST",
		data,
		cancelToken,
	});
};

export const getUserNotificationListing = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/notifications`,
		method: "GET",
		data,
		cancelToken,
	});
};

export const getObjectNotifications = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/notifications/${data.object_id}`,
		method: "GET",
		data,
		cancelToken,
	});
};

export const markNotificationAsRead = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/notifications/mark-read/${data.object_id}`,
		method: "GET",
		data,
		cancelToken,
	});
};

export const markAllNotificationsAsRead = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/notifications/mark-read/all`,
		method: "GET",
		data,
		cancelToken,
	});
};

export const getUserNotificationSettings = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/notification_settings`,
		method: "GET",
		data,
		cancelToken,
	});
};

export const updateUserNotificationSettings = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/notification_settings/update`,
		method: "POST",
		data,
		cancelToken,
	});
};

export const getRefundInformation = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/calculate_refund?booking_id=${data.booking_id}`,
		method: "GET",
		data,
		cancelToken,
	});
};

export const cancelBooking = (data, cancelToken) => {
	const { booking_id, cancellation_reason, custom_cancellation_reason } = data;
	const buildURL =
		cancellation_reason === 5
			? `trips/cancel_trip?booking_id=${booking_id}&cancellation_reason=${cancellation_reason}&other_cancellation_reason=${custom_cancellation_reason}`
			: `trips/cancel_trip?booking_id=${booking_id}&cancellation_reason=${cancellation_reason}`;

	return request({
		...defaultOptions,
		url: `${config.api_ver}/${buildURL}`,
		method: "GET",
		data,
		cancelToken,
	});
};

export const deletePaymentMethod = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/delete_card/${data.card_id}`,
		method: "DELETE",
		data,
		cancelToken,
	});
};

export const setDefaultPaymentMethod = (data, cancelToken) => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/set_default_card/${data.card_id}`,
		method: "GET",
		data,
		cancelToken,
	});
};

export const hostApproveBooking = bookingId => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/booking-requests/approve/${bookingId}`,
		method: "GET",
	});
};

export const hostDismissBooking = bookingId => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/booking-requests/decline/${bookingId}`,
		method: "GET",
	});
};

export const hostCancelBooking = bookingId => {
	return request({
		...defaultOptions,
		url: `${config.api_ver}/booking-requests/cancel/${bookingId}`,
		method: "GET",
	});
};
