import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Close = ({ className, onClick }) => (
    <svg className={className} onClick={onClick} width="8" height="7" viewBox="0 0 8 7" fill="none">
        <path d="M1 0.5L7 6.5" stroke="#797882"/>
        <path d="M7 0.5L1 6.5" stroke="#797882"/>
    </svg>
);

Close.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default memo(Close);